import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import MaskImg from "../../Assets/artist-img.svg";
import background from "../../Assets/CKCII_Mountain/CP_photo_TMS_ViewingPoint_North_s.jpg";
function TaiMoShan({language}) {
  const body_zh1_1 = "大帽山昂然屹立新界中部高達957米，是香港最高峰；環繞其四周的，便是佔地一千四百四十公頃的大帽山郊野公園。成立於一九七九年大帽山郊野公園，東面及南面分別接鄰大埔滘自然謢理區及城門郊野公園，西面為荃錦公路，及一路之隔的大欖郊野公園；北面為林村。";
  const body_zh1_2 = "由於大帽山一帶山巒起伏，農業並不發達，昔日區內農民只能種植稻米和蔬菜作為自用，而種茶亦曾是大帽山地區一種興盛的行業，這裏曾經出產一種很有名的綠茶。在大帽山高處的山坡，向下走的人字形梯田仍清晰可見，相傳這是在十七世紀曾經盛極一時的茶場遺址。";
  const body_zh2 = "大帽山被譽為香港最寒冷的地方, 能一暏香港罕見的結霜景觀。為了呼應這種寒冷的氛圍，大帽山的顏色選擇以灰冷的森林色調為主。這些色彩包括淡灰、冷灰和石灰綠，營造出一種冰冷而神秘的感覺。這種色調與大帽山所在的森林環境相契合，同時也突顯了大帽山的特殊氣候條件。";
  const body_en1_1 = "Tai Mo Shan stands tall and proud at 957 meters in the central New Territories, making it the highest peak in Hong Kong. Surrounding it is the 1,440-hectare Tai Mo Shan Country Park, established in 1979. To the east and south, it borders the Tai Po Kau Nature Reserve and the Shing Mun Country Park, while to the west is Tsuen Kwai Highway and the adjacent Tai Lam Country Park. The northern side is Lin Tong.";
  const body_en1_2 = "Due to the hilly terrain of the Tai Mo Shan area, agriculture has not been very developed. In the past, local farmers could only grow rice and vegetables for self-use. Tea cultivation was also once a thriving industry in the Tai Mo Shan region, producing a famous type of green tea. The distinct zigzag terraced fields on the slopes of Tai Mo Shan, which are still clearly visible, are said to be the remains of tea plantations that were flourishing as far back as the 17th century.";
  const body_en2 = "Tai Mo Shan is renowned as the coldest place in Hong Kong, offering a rare frost landscape. To echo this chilly atmosphere, the color palette of Tai Mo Shan is primarily composed of cool forest tones. These colors include light gray, cool gray, and lime green, creating a cold and mysterious feeling. This palette harmonizes with the forest environment of Tai Mo Shan while also highlighting its unique climatic conditions";
  return (
    <div>

    {language === 'zh' ? (
              <Container className="home-content" >
                <Row className="background-white" >
                  <Col className="popup-header-zh">
                  大帽山
                  </Col>
                    <div className="box" style={{marginLeft:12, backgroundImage: `url(${background})`}}>
                      <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
                    </div>
                    <p className="about-artist-body">
                    簡介
                    </p>
                      <p className="about-artist-body">{body_zh1_1}<br/><br/>{body_zh1_2}</p>
                    <p className="about-artist-body">
                    主色調：淡灰、冷灰和石灰綠
                    </p>
                    <p className="about-artist-body">{body_zh2}</p>
                </Row>
              </Container>
            ) : (
              <Container className="home-content" >
                <Row className="background-white" >
                  <Col className="popup-header-zh">
                  TAI MO SHAN
                  </Col>
                    <div className="box" style={{marginLeft:12, backgroundImage: `url(${background})`}}>
                      <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
                    </div>
                    <p className="about-artist-body">
                    Introduction
                    </p>
                      <p className="about-artist-body">{body_en1_1}<br/><br/>{body_en1_2}</p>
                    <p className="about-artist-body">
                    COLOR：BLUE + GREY（GREY COOL FOREST TONES）
                    </p>
                    <p className="about-artist-body">{body_en2}</p>
                </Row>
              </Container>
              )}
      </div>
  );
}

export default TaiMoShan;