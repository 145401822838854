import { Container, Row, Col} from "react-bootstrap";
import React, { useState, useEffect , Suspense , useRef ,Button} from "react";
import Model_LT from '../../components/Home/Model_LT';
import { Environment, OrbitControls, useFBX ,PresentationControls ,useHelper} from '@react-three/drei'
import { Canvas} from '@react-three/fiber'
import { motion } from "framer-motion";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgArrowTopRight } from "react-icons/cg";

function DashboardLT(props) {
  const BoxBGC = "#cf5a3a";
  const BoxHeaderC = "#faae55";
  const BoxHeader_zh = "林村";
  const BoxHeader = "LAM";
  const BoxHeader2 = "TSUEN";
  const HEIGHT = "585m";
  const COLOR_zh = "綠色";
  const COLOR_en = "BROWN+PURPLE";
  const BODY1_zh = "林村位於新界北部，橫跨大埔區、北區和元朗區， 最高點桂角山，達585米。";
  const BODY1_en = "Lam Tsuen is located in the northern New Territories, spanning the Tai Po, North, and Yuen Long districts. Its highest point is Kwai Kuk Shan, reaching 585 meters.";
  const BODY2_zh = "";
  const BODY2_en = "";
  const [isCurrent, setIsCurrent] = useState(true);
  
  return (
    <div>
      {props.language === 'zh' ? (
        <Container >
          <Row >
            <div>
            <Col style={{ paddingTop:67 }}>
                <motion.div className=" dashboard-box " style={{backgroundColor: BoxBGC }}
                    initial={{ opacity: 0, x:100 }}
                    animate={{ opacity: 1, x:0 }}
                    transition={{ type: "spring", duration: 1, delay: 0.5, ease: [0, 0.71, 0.2, 1.01] }}>
                      {isCurrent ?
                      <section>
                        <div className="current icon-flex-cur" style={{color: "#1d1d1b"}}>
                          <div className="current-div" style={{display: "none"}}>
                            現場
                          </div>
                        </div>
                      </section>
                      : null }
                </motion.div>
                <motion.h1 className="dashboard-img-header" style={{color: BoxHeaderC }}
                    initial={{ opacity: 0, x:100 }}
                    animate={{ opacity: 1, x:0 }}
                    transition={{ type: "spring", duration: 1, delay: 0.6, ease: [0, 0.71, 0.2, 1.01] }}>
                    {BoxHeader_zh}
                </motion.h1>
            </Col>              
            </div>
            <Row>
              {/* Start Here */}
                <Row style={{paddingBottom:"35px", paddingTop:"35px"}}>
                  <Col>
                  <div className="left-border">
                      <Row className="dashboard-header">
                        高度
                      </Row>
                      <Row className="dashboard-grey">
                      {HEIGHT}
                      </Row>
                      <Row className="home-about-body">
                      {BODY1_zh}
                      </Row>
                  </div>
                  </Col> 
                  <Col>
                  <div className="left-border">
                      <Row className="dashboard-header">
                        主色調
                      </Row>
                      <Row className="dashboard-grey">
                        {COLOR_zh}
                      </Row>
                      <Row className="home-about-body">
                      {BODY2_zh}
                      </Row>
                  </div>
                  </Col> 
                </Row>
                {/* End Here */}
            </Row>
          </Row>
        </Container>
      ) : (
        <Container >
          <Row >
            <div>
            <Col style={{ paddingTop:67 }}>
                <motion.div className=" dashboard-box " style={{backgroundColor: BoxBGC }}
                    initial={{ opacity: 0, x:100 }}
                    animate={{ opacity: 1, x:0 }}
                    transition={{ type: "spring", duration: 1, delay: 0.5, ease: [0, 0.71, 0.2, 1.01] }}>
                      {isCurrent ?
                      <section>
                        <div className="current icon-flex-cur" style={{color: "#1d1d1b"}}>
                          <div className="current-div" style={{display: "none"}}>
                          Current
                          </div>
                        </div>
                      </section>
                      : null }
                </motion.div>
                <motion.h1 className="dashboard-img-header-en" style={{color: BoxHeaderC }}
                    initial={{ opacity: 0, x:100 }}
                    animate={{ opacity: 1, x:0 }}
                    transition={{ type: "spring", duration: 1, delay: 0.6, ease: [0, 0.71, 0.2, 1.01] }}>
                    {BoxHeader}<br/>{BoxHeader2}
                </motion.h1>
            </Col>              
            </div>
            <Row>
              {/* Start Here */}
                <Row style={{paddingBottom:"35px", paddingTop:"35px"}}>
                  <Col>
                  <div className="left-border">
                      <Row className="dashboard-header">
                      HEIGHT
                      </Row>
                      <Row className="dashboard-grey">
                      {HEIGHT}
                      </Row>
                      <Row className="home-about-body">
                      {BODY1_en}
                      </Row>
                  </div>
                  </Col> 
                  <Col>
                  <div className="left-border">
                      <Row className="dashboard-header">
                        COLOUR
                      </Row>
                      <Row className="dashboard-grey">
                        {COLOR_en}
                      </Row>
                      <Row className="home-about-body">
                      {BODY2_en}
                      </Row>
                  </div>
                  </Col> 
                </Row>
                {/* End Here */}
            </Row>
          </Row>
        </Container>
      )}
      </div>
    );
  }

export default DashboardLT;