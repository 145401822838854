
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { shininess } from 'three/examples/jsm/nodes/Nodes.js';



export function Model_LR(props) {
    const { nodes, materials } = useGLTF('/LRGLTF.gltf')
    // {[0.2, -0.5, 0]}{[0.45, 0.7, 0.1]}
    return (
      <group {...props} dispose={null}>
        <group position={[0.2, -1, 0]} rotation={[0.45, 0.4, 0.1]} scale={0.00299}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.EXPORT_GOOGLE_SAT_WM002.geometry}
          material={materials.rastMat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.EXPORT_GOOGLE_SAT_WM002_1.geometry}
          material={materials['Material.001']}
        />
        </group>
      </group>
    )
  }

export default Model_LR;