import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { CgArrowTopRight } from "react-icons/cg";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

function Footer({ language }) {
  const date = new Date();
  const showTime = date.getHours() 
      + ':' + date.getMinutes() 
  return (
  <>
    {language === 'zh' ? (
    <Container fluid className="footer">
        <Col style={{ display: 'flex', justifyContent: 'space-between' , fontSize:"1.2rem" , letterSpacing:"1px"}}>
              <div className="time">{showTime}</div>
              <Nav.Link as={Link} className="show-schedule-link" to="/Schedule">
              時間表
                <CgArrowTopRight style={{fontSize:"1.6rem"}}/>
              </Nav.Link>
              {/* <Nav.Link as={Link} to="/Schedule" >Space</Nav.Link> */}
        </Col>
    </Container>
  ):(
    <Container fluid className="footer">
    <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="time">{showTime}</div>
          <Nav.Link as={Link} className="show-schedule-link" to="/Schedule">
            Show Schedule
            <CgArrowTopRight />
          </Nav.Link>
          {/* <Nav.Link as={Link} to="/Schedule" >Space</Nav.Link> */}
    </Col>
</Container>
  )}
  </>
  );
}
export default Footer;
