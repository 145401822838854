import React, { useState, useEffect , Suspense , useRef } from "react";
import DashboardLR from "./DashboardLR";
import DashboardLT from "./DashboardLT";
import DashboardMOS from './DashboardMOS';
import DashboardPSL from './DashboardPSL';
import DashboardTMS from './DashboardTMS';
import DashboardVP from './DashboardVP';
import { motion } from "framer-motion";
import Model_VP from './Model_VP.js';
import Model_TMS from './Model_TMS.js';
import Model_PSL from './Model_PSL.js';
import Model_MOS from './Model_MOS.js';
import Model_LT from './Model_LT.js';
import Model_LR from './Model_LR.js';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Canvas} from '@react-three/fiber';
import { Environment, OrbitControls ,useHelper } from '@react-three/drei';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgArrowTopRight } from "react-icons/cg";

const Dashboard = ({ language }) => {
  const Model_V_min = 0;
  const Model_V_max = Math.PI/1.8;
  const Model_H_min = Math.PI/-3.25;
  const Model_H_max = Math.PI/3.25;
  const BoxBGC = "none";
  const Mlist = [<Model_VP />, <Model_TMS />, <Model_PSL />, <Model_MOS />, <Model_LT />, <Model_LR />];
  const Mlink = ["/VP" , "/TMS" , "/PSL" , "/MOS" , "/LT" , "/LR"];

  const opc = {
    in: { opacity: 1 , y: 0 },
    out: { opacity: 0 },
  }

     const [num, setNum] = useState(0);
     const [isButtonDisabled, setIsButtonDisabled] = useState(false);

      const CustomLeftArrow = ({ onClick, ...rest }) => {
      const {
        onMove,
        carouselState: { currentSlide, deviceType }
      } = rest;

      const handleClick = () => {
        onClick();
        setNum((prevNum) => (prevNum - 1 + 6) % 6 );

        if (isButtonDisabled) return;
        setIsButtonDisabled(true);
        const timer = setTimeout(() => {
          setIsButtonDisabled(false);
        }, 500);// Disable the button for 2 seconds
        return () => clearTimeout(timer);// Clean up the timer when the component unmounts
      };
    
      return (
        <button onClick={handleClick} disabled={isButtonDisabled}>
          <div className="prev">
            {"⟩"}
          </div>
        </button>
      );
    };


     const CustomRightArrow = ({ onClick, ...rest }) => {
       const {
         onMove,
         carouselState: { currentSlide, deviceType },
       } = rest;
     
       const handleClick = () => {
         onClick();
         setNum((prevNum) => (prevNum + 1) % 6 );

         if (isButtonDisabled) return;
         setIsButtonDisabled(true);
         const timer = setTimeout(() => {
           setIsButtonDisabled(false);
           console.log(isButtonDisabled)
         }, 500);// Disable the button for 2 seconds
         return () => clearTimeout(timer);// Clean up the timer when the component unmounts
       };
     
       return (
         <button onClick={handleClick} disabled={isButtonDisabled}>
           <div className="next">
             {"⟩"}
           </div>
         </button>
       );
     };

  return (
    <div>
      <div style={{zIndex:99}}>
      <Carousel
        // additionalTransfrom={0}
        arrows
        // autoPlaySpeed={3000}
        // centerMode={false}
        // className=""
        // containerClass=""
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        dotListClass=""
        draggable={false}
        focusOnSelect={false}
        infinite
        // renderArrowsWhenDisabled={false}
        // renderButtonGroupOutside={false}
        // renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: 1,
            partialVisibilityGutter: 40
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: 1,
            partialVisibilityGutter: 30
          }
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        // shouldResetAutoplay
        // showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable={false}
      >
        <DashboardVP language={language} num={num}/>
        <DashboardTMS language={language} num={num}/>
        <DashboardPSL language={language} num={num}/>
        <DashboardMOS language={language} num={num}/>
        <DashboardLT language={language} num={num}/>
        <DashboardLR language={language} num={num}/>
      </Carousel>
      </div>
        <motion.div 
          className= " main-box " style={{ backgroundColor: BoxBGC }}
          initial={{ opacity: 0, y: -10 }}
          animate={ isButtonDisabled ? "out" : "in"}
          variants={opc}
          transition={{ type: "tween", duration: 0.4 , delay: 0 , ease: [0, 1, 0.9, 1.1] }}>     
          {language === 'zh' ? ( 
            <section>
              <Nav.Link as={Link} className="dashboard-arrow-link icon-flex" to= { Mlink[num] } style={{color: "#f1f1f1"}}>
                <div className="dblink_zh">
                  了解更多
                  <CgArrowTopRight className="btn-primary" style={{ backgroundColor: "#1d1d1b" }}/>
                </div>
              </Nav.Link>
            </section>
           ) : (
            <section>
                  <Nav.Link as={Link} className="dashboard-arrow-link-en icon-flex" to={ Mlink[num] } style={{color: "#f1f1f1" , padding:"20px"}}>
                    <div className="dblink_en">
                      About the area
                      <CgArrowTopRight className="btn-primary" style={{ backgroundColor: "#1d1d1b" }}/>
                    </div>
                  </Nav.Link>
            </section>
          )}

            <Canvas className="canvas-3d">
                <Suspense fallback={null}>
                  <ambientLight intensity={3} />
                  <directionalLight position={[-1, 10, -2]} intensity={20}/>
                  {Mlist[num]}
                  <OrbitControls enablePan={false} minPolarAngle={Model_V_min} maxPolarAngle={Model_V_max} minAzimuthAngle={Model_H_min} maxAzimuthAngle={Model_H_max} minDistance={3} maxDistance={10}/>
                  <Environment preset="sunset" />
                  {/* <axesHelper args={[20]} /> */}
                </Suspense>
            </Canvas>
          </motion.div>

    </div>
  );
}

export default Dashboard;
