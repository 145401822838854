import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import MaskImg from "../../Assets/artist-img3.svg";
import background from "../../Assets/About/d2.jpg";

function AboutArchitect ({language}) {
  return (
    <div>
    {language === 'zh' ? (
          <Container className="home-content">
            <Row className="background-white" >
              <Col className="about-artist-header">
              <div className="about-artist-subheader">
              <br/>
              <br/>
              建築師
              </div>
              DRAUGHTZMAN
              </Col>
              
                <div className="box" style={{marginLeft:12, backgroundImage: `url(${background})`}}>
                  <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
                </div>
                  <p className="about-artist-body">Draughtzman，一家專注於建築室內設計和敘事空間的室內設計公司</p>

                  
            </Row>
          </Container>
        ) : (
          <Container className="home-content">
            <Row className="background-white" >
              <Col className="about-artist-header">

              <div className="about-artist-subheader">
              <br/>
              <br/>
              Architect
              </div>
              DRAUGHTZMAN
              </Col>
                <div className="box" style={{marginLeft:12, backgroundImage: `url(${background})`}}>
                  <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
                </div>
                  <p className="about-artist-body">Draughtzman, an interior design firm specializes in architectural interiors and narrative space.</p>
            </Row>
          </Container>
        )}
    </div>
  )
}
export default AboutArchitect;