import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { CgArrowTopRight } from "react-icons/cg";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

function Schedule({language}) {
  return (
    <div>
    {language === 'zh' ? (
    <Row style={{paddingLeft:12, paddingRight:12 , maxWidth: "100%" , overflowX: "hidden"}}>
      <Container fluid id="home" className="home-content">
        <Container className="background-white">
            <Row>
              <Col className="content-header-zh" style={{ paddingTop:80 }}>
                時間表
              </Col>
              {/* Start Here */}
              <Row>
                <Col>
                <div className="left-border-sch">
                    <Row className="schedule-header">
                      08:00 - 10:00<br />
                      12:00 - 14:00<br />
                      17:00 - 19:00
                      <Col className="schedule-time "  style={{display:"none"}}>標題1</Col>
                    </Row>
                    <Row className="schedule-grey" style={{textAlign:"right"}}>
                    繁忙時間
                    <Col>
                    <Nav.Link as={Link} className="schedule-arrow-link icon-flex" to="/Peak" style={{visibility:"hidden"}}>
                      <CgArrowTopRight />
                    </Nav.Link>
                    </Col>
                  </Row>
                  <Row className="bottom-border"></Row>
              </div>
              </Col> 
            </Row>
              {/* End Here */}
              <div><br/></div>
             {/* Start Here */}
            <Row>
              <Col>
              <div className="left-border-sch">
                  <Row className="schedule-header">
                    10:00 - 12:00<br />
                    14:00 - 17:00<br />
                    19:00 - 21:00
                    <Col className="schedule-time " style={{display:"none"}}>標題2</Col>
                  </Row>
                  <Row className="schedule-grey">
                    非繁忙時間
                    <Col>
                    <Nav.Link as={Link} className="schedule-arrow-link icon-flex" to="/NPeak"  style={{visibility:"hidden"}}>
                      <CgArrowTopRight />
                    </Nav.Link>
                    </Col>
                  </Row>
                  <Row className="bottom-border"></Row>
              </div>
              </Col> 
            </Row>
            {/* End Here */}
            </Row>
            <Row></Row>
          </Container>
      </Container>
    </Row>
     ) : (
      <Row style={{paddingLeft:12, paddingRight:12 , maxWidth: "100%" , overflowX: "hidden"}}>
      <Container fluid id="home" className="home-content">
        <Container className="background-white">
            <Row>
              <Col className="content-header-en" style={{ paddingTop:80 }}>
                SHOW<br />
                SCHEDULE
              </Col>
              {/* Start Here */}
              <Row>
                <Col>
                <div className="left-border-sch">
                    <Row className="schedule-header">
                      08:00 - 10:00<br />
                      12:00 - 14:00<br />
                      17:00 - 19:00
                      <Col className="schedule-time "  style={{visibility:"hidden"}}>Content1</Col>
                    </Row>
                    <Row className="schedule-grey">
                    Peak Hour
                    <Col>
                    <Nav.Link as={Link} className="schedule-arrow-link icon-flex" to="/Peak"  style={{visibility:"hidden"}}>
                      <CgArrowTopRight />
                    </Nav.Link>
                    </Col>
                  </Row>
                  <Row className="bottom-border"></Row>
              </div>
              </Col> 
            </Row>
              {/* End Here */}
              <div><br/></div>
             {/* Start Here */}
            <Row>
              <Col>
              <div className="left-border-sch">
                  <Row className="schedule-header">
                    10:00 - 12:00<br />
                    14:00 - 17:00<br />
                    19:00 - 21:00
                    <Col className="schedule-time "  style={{visibility:"hidden"}}>Content2</Col>
                  </Row>
                  <Row className="schedule-grey">
                    Non Peak Hour
                    <Col>
                    <Nav.Link as={Link} className="schedule-arrow-link icon-flex" to="/NPeak"  style={{visibility:"hidden"}}>
                      <CgArrowTopRight />
                    </Nav.Link>
                    </Col>
                  </Row>
                  <Row className="bottom-border"></Row>
              </div>
              </Col> 
            </Row>
            <Row></Row>
            {/* End Here */}
            </Row>
        </Container>
      </Container>
    </Row>
    )}
    </div>
  );
}

export default Schedule;