import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import MaskImg from "../../Assets/artist-img.svg";
import background from "../../Assets/CKCII_Mountain/CP_photo_MOS_MaOnShan_s.jpg";
function MaOnShan({language}) {
  const body_zh1_1 = "馬鞍山位於新界東部， 高702米，與牛押山相對，形成馬鞍形狀，故名馬鞍山。郊遊人士將它分為馬鞍頭（即馬鞍山主峰）及馬鞍尾（牛押山）。在新界多處地方都可以看見這個「馬鞍」。";
  const body_zh1_2 = "馬鞍山郊野公園最獨特之處，是它把西貢半島和沙田分隔開。由於馬鞍山東北面登山的路較為險要，所以由南面出發，經昂平高地登山較佳。";
  const body_zh1_3 = "沿東面下山，有險峻而迂迴的小路繞過寧靜的山坡及幽谷。在人跡罕至之處可找到稀有的植物品種，因此附近一帶已劃為特別地區，加以保育。";
  const body_zh2 = "馬鞍山我們以馬鞍山公園的迷宮與屯馬線作主題, 公園的迷宮是該公園的中心設施，它不僅吸引人們前來拍照，還提供了遊玩的樂趣, 而在夜晚，迷宮則以紫色燈光為主，營造出一種神秘而迷人的夜景。啡色是屯馬線的主要色調，象徵著自然和大地的元素，同時也與公園周圍的自然環境相契合。";
  const body_en1_1 = "Ma On Shan, located in the eastern New Territories, stands at 702 meters and is positioned opposite Ngau Pa Shan, forming the shape of a saddle, hence the name 'Ma On Shan' (Saddle Mountain). Hikers divide it into the 'Ma On Shan Head' (the main peak of Ma On Shan) and the 'Ma On Shan Tail' (Ngau Pa Shan). This 'saddle' shape can be seen in various locations across the New Territories.";
  const body_en1_2 = "The most unique feature of the Ma On Shan Country Park is that it separates the Sai Kung Peninsula and Sha Tin. Due to the more treacherous northeast ascent of Ma On Shan, it is better to approach from the south, via the Ngong Ping Plateau.";
  const body_en1_3 = "Descending along the eastern side, there is a steep and winding path that winds around the peaceful slopes and secluded valleys. In these rarely visited areas, rare plant species can be found, and the surrounding region has been designated as a Special Area for conservation purposes.";
  const body_en2 = "Ma On Shan is themed around the maze in Ma On Shan Park and the Tuen Ma Line. The maze is the park's central feature, attracting visitors for photos and providing playful fun. At night, the maze is illuminated with purple lights, creating a mysterious and enchanting nighttime scene. Brown serves as the main color of the Tuen Ma Line, symbolizing elements of nature and the earth, while also harmonizing with the natural environment surrounding the park.";
  return (
    <div>

    {language === 'zh' ? (
              <Container className="home-content" >
                <Row className="background-white" >
                  <Col className="popup-header-zh">
                  馬鞍山
                  </Col>
                    <div className="box" style={{marginLeft:12, backgroundImage: `url(${background})`}}>
                      <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
                    </div>
                    <p className="about-artist-body">
                    簡介
                    </p>
                    <p className="about-artist-body">{body_zh1_1}<br/><br/>{body_zh1_2}<br/><br/>{body_zh1_3}</p>
                    <p className="about-artist-body">
                    主色調：啡色和紫色
                    </p>
                    <p className="about-artist-body">{body_zh2}</p>
                </Row>
              </Container>
            ) : (
              <Container className="home-content" >
                <Row className="background-white" >
                  <Col className="popup-header-zh">
                  MA ON SHAN
                  </Col>
                    <div className="box" style={{marginLeft:12, backgroundImage: `url(${background})`}}>
                      <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
                    </div>
                    <p className="about-artist-body">
                    Introduction
                    </p>
                      <p className="about-artist-body">{body_en1_1}<br/><br/>{body_en1_2}<br/><br/>{body_en1_3}</p>
                    <p className="about-artist-body">
                    COLOR：BROWN ＋ PURPLE
                    </p>
                    <p className="about-artist-body">{body_en2}</p>
                </Row>
              </Container>
              )}
      </div>
  );
}

export default MaOnShan;