import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import MaskImg from "../../Assets/artist-img.svg";
import background from "../../Assets/CKCII_Mountain/0601-TC_hk-The-Peak-Tower.jpg";
function VictoriaPeak({language}) {
  const body_zh1_1 = "太平山位於香港島西部，屬於中西區的一部分，高552米，為香港島最高峰。廣義上的山頂區是包括太平山的主峰、爐峰峽、歌賦山和奇力山等等的山嶺地形。";
  const body_zh1_2 = "太平山爐峰峽一帶因景觀開揚，能觀賞維多利亞港兩岸絕佳景色，結合山頂纜車和凌霄閣，因而成為香港最重要的旅遊景點，太平山夜景更享譽全球，成為世界三大夜景之一。";
  const body_zh1_3 = "對於香港人來說，九龍獅子山象徵奮鬥拼搏的「香港精神」，太平山則是象徵「東方之珠」的繁榮成就。";
  const body_zh2_1 = "太平山以其山頂為主題，並以綠色和白色作為主要色調，以呼應山中樹木的生機與自然之美。";
  const body_zh2_2 = "對於香港人來說，九龍的獅子山象徵著奮鬥拼搏的「香港精神」，而太平山則代表著「東方之珠」的繁榮與成就。綠色的色調象徵著生命與活力，與山中繁茂的樹木交相輝映，展現出自然的美好；而白色則代表著純淨與希望，象徵著香港美好的未來。";
  const body_zh2_3 = "整體而言，太平山以山頂為主題，選擇綠色和白色作為主色調，彰顯了自然與繁榮的結合。這樣的設計不僅彰顯了太平山的自然美景，也傳達了香港的歷史與文化意義，使其成為一個兼具視覺吸引力和深厚內涵的地標。無論是白天的壯麗景色，還是夜晚的璀璨燈火，太平山始終以其獨特的魅力吸引著無數人流連忘返。";
  const body_en1_1 = "Victoria Peak is located in the western part of Hong Kong Island, belonging to the Central and Western District. It stands at an elevation of 552 meters, making it the highest peak on Hong Kong Island. The broader Tai Pai Shan area encompasses the main peak, Lau Fung Tsai, Kau Fu Shan, and Qi Li Shan.";
  const body_en1_2 = "The Lau Fung Tsai area of Victoria Peak offers expansive views, allowing one to appreciate the stunning scenery of both sides of Victoria Harbour. Combining the Peak Tram and the Ngong Ping 360 cable car, this area has become one of Hong Kong's most important tourist attractions. The night view of Tai Ping Shan is also renowned globally, ranking as one of the three greatest night views in the world.";
  const body_en1_3 = "For the people of Hong Kong, Lion Rock in Kowloon symbolizes the \"Hong Kong spirit\" of hard work and perseverance, while Victoria Peak represents the prosperity and success of the \"Pearl of the Orient\".";
  const body_en2 = "Victoria Peak is themed around its summit, using green and white as the main colors to reflect the vitality of the trees and the beauty of nature. After World War II, Hong Kong gradually developed into a major tourist city in Asia. The area around the Peak's scenic viewpoint became popular among tourists for its expansive views of Victoria Harbour. With the combination of the Peak Tram and Sky Terrace, Victoria Peak has become one of Hong Kong's most important tourist attractions. Particularly at night, the view from the Peak is renowned worldwide, considered one of the top three night views globally, attracting countless visitors. For Hong Kong residents, Lion Rock symbolizes the \"Hong Kong Spirit\" of struggle and perseverance, while Victoria Peak represents the prosperity and achievements of the \"Pearl of the Orient.\" The green tones symbolize life and vitality, reflecting the lush trees of the mountain, showcasing the beauty of nature; white represents purity and hope, symbolizing a bright future for Hong Kong. Overall, Victoria Peak's theme focuses on its summit, choosing green and white as the primary color palette to highlight the fusion of nature and prosperity. This design not only showcases the natural beauty of Victoria Peak but also conveys the historical and cultural significance of Hong Kong, making it a landmark with both visual appeal and deep meaning. Whether during the day with its magnificent views or at night with its dazzling lights, Victoria Peak continuously attracts countless visitors with its unique charm.";
  return (
    <div>
    {language === 'zh' ? (
          <Container className="home-content" >
            <Row className="background-white" >
              <Col className="popup-header-zh">
              太平山
              </Col>
              <div className="box" style={{marginLeft:12, backgroundImage: `url(${background})`}}>
                  <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
                </div>
                <p className="about-artist-body">
                簡介
                </p>
                  <p className="about-artist-body">{body_zh1_1}<br/><br/>{body_zh1_2}<br/><br/>{body_zh1_3}</p>
                <p className="about-artist-body">
                主色調：綠色和白色
                </p>
                <p className="about-artist-body">{body_zh2_1}<br/><br/>{body_zh2_2}<br/><br/>{body_zh2_3}</p>
            </Row>
          </Container>
        ) : (
          <Container className="home-content" >
            <Row className="background-white" >
              <Col className="popup-header-zh">
              VICTORIA PEAK
              </Col>
              <div className="box" style={{marginLeft:12, backgroundImage: `url(${background})`}}>
                  <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
                </div>
                <p className="about-artist-body">
                Introduction
                </p>
                  <p className="about-artist-body">{body_en1_1}<br/><br/>{body_en1_2}<br/><br/>{body_en1_3}</p>
                <p className="about-artist-body">
                COLOUR: GREEN + WHITE
                </p>
                <p className="about-artist-body">{body_en2}</p>
            </Row>
          </Container>
          )}
      </div>
  );
}

export default VictoriaPeak;