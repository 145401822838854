import React from "react";
import { Container, Row, Col} from "react-bootstrap";

function TandC({language}) {
  console.log(language)
  return (
    <div>
    {language === 'zh' ? (
        <Row style={{paddingLeft:12, paddingRight:12 , maxWidth: "100%" , overflowX: "hidden" ,paddingBottom:"20vw"}}>
        <Container fluid className="home-content" id="home">
          <Container className="background-white">
              <Row>
                <Col className="content-header-zh" style={{ paddingTop:80 }}>免責</Col>
                    <div className="about-artist-body">標題 1</div>
                    <p className="about-artist-body">
                    本網站所載的資料及內容由積金局編製，僅供參考。積金局就詮釋法律或處理若干問題所提供的資料，純粹根據一般情況提供，當中並無考慮個別人士的具體情況，因此該等資料不應取代專業意見。任何人士在按本網站的資料就相關事宜採取行動前，應先尋求獨立的法律意見或其他專業意見。
                    </p>
                    <div className="about-artist-body">標題 2</div>
                    <p className="about-artist-body">
                    本網站所載的資料及內容由積金局編製，僅供參考。積金局就詮釋法律或處理若干問題所提供的資料，純粹根據一般情況提供，當中並無考慮個別人士的具體情況，因此該等資料不應取代專業意見。任何人士在按本網站的資料就相關事宜採取行動前，應先尋求獨立的法律意見或其他專業意見。
                    </p>
              </Row>
          </Container>
        </Container>
        </Row>
    ) : (
        <Row style={{paddingLeft:12, paddingRight:12 , maxWidth: "100%" , overflowX: "hidden" , paddingBottom:"20vw"}}>
        <Container fluid className="home-content" id="home">
          <Container className="background-white">
              <Row>
                <Col className="content-header-en" style={{ paddingTop:80 }}>TERMS & CONDITIONS</Col>
                    <div className="about-artist-body">Title 1</div>
                    <p className="about-artist-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br />Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <div className="about-artist-body">Title 2</div>
                    <p className="about-artist-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br />Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
              </Row>
          </Container>
        </Container>
        </Row>
    )}
    </div>
  );
}

export default TandC;