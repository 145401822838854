import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Model_MOS(props) {
  const { nodes, materials } = useGLTF('/MOSGLTF.gltf')
  return (
    <group {...props} dispose={null}>
      <group position={[0.4, -0.5, 0]} rotation={[0.5, 0.7, 0.2]} scale={0.0025}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.EXPORT_GOOGLE_SAT_WM008.geometry}
          material={materials.MOS_Top}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.EXPORT_GOOGLE_SAT_WM008_1.geometry}
          material={materials.MOS_Bottom}
        />
      </group>
    </group>
  )
}

export default Model_MOS;