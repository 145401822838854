import { Container, Row, Col} from "react-bootstrap";
import React, { useState, useEffect , Suspense , useRef ,Button} from "react";
import { motion } from "framer-motion";

function DashboardLR(props) {
  const BoxBGC = "#5f310e";
  const BoxHeaderC = "#dcaa68";
  const BoxHeader_zh = "獅子山";
  const BoxHeader = "LION";
  const BoxHeader2 = "ROCK";
  const HEIGHT = "495m";
  const COLOR_zh = "棕色、黃褐色和金色";
  const COLOR_en = "YELLOW＋BROWN";
  const BODY1_zh = "獅子山位於九龍和新界之間，九龍群山之一， 高495米。";
  const BODY1_en = "Lion Rock is situated between Kowloon and the New Territories, as part of the Kowloon mountain range, reaching 495 meters in height.";
  const BODY2_zh = "";
  const BODY2_en = "";
  const [isCurrent, setIsCurrent] = useState(true);
  
  return (
    <div>
      {props.language === 'zh' ? (
        <Container >
          <Row >
            <div>
            <Col style={{ paddingTop:67 }}>
                <motion.div className=" dashboard-box " style={{backgroundColor: BoxBGC }}
                    initial={{ opacity: 0, x:100 }}
                    animate={{ opacity: 1, x:0 }}
                    transition={{ type: "spring", duration: 1, delay: 0.5, ease: [0, 0.71, 0.2, 1.01] }}>
                      {isCurrent ?
                      <section>
                        <div className="current icon-flex-cur" style={{color: "#1d1d1b"}}>
                          <div className="current-div" style={{display: "none"}}>
                            現場
                          </div>
                        </div>
                      </section>
                      : null }
                </motion.div>
                <motion.h1 className="dashboard-img-header" style={{color: BoxHeaderC }}
                    initial={{ opacity: 0, x:100 }}
                    animate={{ opacity: 1, x:0 }}
                    transition={{ type: "spring", duration: 1, delay: 0.6, ease: [0, 0.71, 0.2, 1.01] }}>
                    {BoxHeader_zh}
                </motion.h1>
            </Col>              
            </div>
            <Row>
              {/* Start Here */}
                <Row style={{paddingBottom:"35px", paddingTop:"35px"}}>
                  <Col>
                  <div className="left-border">
                      <Row className="dashboard-header">
                        高度
                      </Row>
                      <Row className="dashboard-grey">
                      {HEIGHT}
                      </Row>
                      <Row className="home-about-body">
                      {BODY1_zh}
                      </Row>
                  </div>
                  </Col> 
                  <Col>
                  <div className="left-border">
                      <Row className="dashboard-header">
                        主色調
                      </Row>
                      <Row className="dashboard-grey">
                        {COLOR_zh}
                      </Row>
                      <Row className="home-about-body">
                      {BODY2_zh}
                      </Row>
                  </div>
                  </Col> 
                </Row>
                {/* End Here */}
            </Row>
          </Row>
        </Container>
      ) : (
        <Container >
          <Row >
            <div>
            <Col style={{ paddingTop:67 }}>
                <motion.div className=" dashboard-box " style={{backgroundColor: BoxBGC }}
                    initial={{ opacity: 0, x:100 }}
                    animate={{ opacity: 1, x:0 }}
                    transition={{ type: "spring", duration: 1, delay: 0.5, ease: [0, 0.71, 0.2, 1.01] }}>
                      {isCurrent ?
                      <section>
                        <div className="current icon-flex-cur" style={{color: "#1d1d1b"}}>
                          <div className="current-div" style={{display: "none"}}>
                          Current
                          </div>
                        </div>
                      </section>
                      : null }
                </motion.div>
                <motion.h1 className="dashboard-img-header-en" style={{color: BoxHeaderC }}
                    initial={{ opacity: 0, x:100 }}
                    animate={{ opacity: 1, x:0 }}
                    transition={{ type: "spring", duration: 1, delay: 0.6, ease: [0, 0.71, 0.2, 1.01] }}>
                    {BoxHeader}<br/>{BoxHeader2}
                </motion.h1>
            </Col>              
            </div>
            <Row>
              {/* Start Here */}
                <Row style={{paddingBottom:"35px", paddingTop:"35px"}}>
                  <Col>
                  <div className="left-border">
                      <Row className="dashboard-header">
                      HEIGHT
                      </Row>
                      <Row className="dashboard-grey">
                      {HEIGHT}
                      </Row>
                      <Row className="home-about-body">
                      {BODY1_en}
                      </Row>
                  </div>
                  </Col> 
                  <Col>
                  <div className="left-border">
                      <Row className="dashboard-header">
                        COLOUR
                      </Row>
                      <Row className="dashboard-grey">
                        {COLOR_en}
                      </Row>
                      <Row className="home-about-body">
                      {BODY2_en}
                      </Row>
                  </div>
                  </Col> 
                </Row>
                {/* End Here */}
            </Row>
          </Row>
        </Container>
      )}
      </div>
    );
  }

export default DashboardLR;