import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import VP from "./components/Popup/VictoriaPeak";
import TMS from "./components/Popup/TaiMoShan";
import PSL from "./components/Popup/PatSinLeng";
import MOS from "./components/Popup/MaOnShan";
import LT from "./components/Popup/LamTsuen";
import Dashboard from "./components/Home/Dashboard";
import About from "./components/About/About";
import Footer from "./components/Footer";
import Schedule from "./components/Schedule";
import TandC from "./components/TandC";
import LR from "./components/Popup/LionRock";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row} from "react-bootstrap";
import Terrain from "./components/terrain";
import PeakHour from "./components/Popup/PeakHour";
import NonPeakHour from "./components/Popup/NonPeakHour";
import Test from "./components/Home/Dashboard3";


function App({  }) {
  // const parsed = queryString.parse(window.location.search);
  // console.log(parsed);
  const [load, upadateLoad] = useState(true);

  useEffect(() => 
    {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
    }, []);

    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [isLanguageSelected,setIsLanguageSelected] = useState(null);
  
    useEffect(() => {
      if (selectedLanguage === 'zh' ) {// Update the page language based on the selected language
        setIsLanguageSelected(true);
      }else if (selectedLanguage === 'en' ) {// Update the page language based on the selected language
        setIsLanguageSelected(true);
      }
      // return () =>{};
    }, [isLanguageSelected]);
  
    const handleLanguageChange = (language) => {
      setSelectedLanguage(language);
      setIsLanguageSelected(true);
    };
    

  return (
    <Router >
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        {isLanguageSelected ? <Navbar load={load} language={selectedLanguage}/>: null }
        <ScrollToTop />
          {isLanguageSelected  || load ? null:
          <Container >
          <div className="terrain-background">
              <div className="terrain" >
                <Terrain />
              </div>
            <div className="quote-lan">
              <button style={{marginLeft: 3}} className="lan" onClick={() => handleLanguageChange("zh")} herf="/">
                中文
              </button>
              <Row style={{ display:"flex" , justifyContent:"center", fontSize:"0.8rem"}}>|</Row>
              <button className="lan" onClick={() => handleLanguageChange("en")} herf="/">
                ENGLISH
              </button>
            </div>
            <div style={{margin:"auto"}}>
              {/* <div className="lan-header">CKCLL </div><br/> */}
              <div className="lan-header"><span>CKC ll <br/>MEDIA WALL</span></div>
            </div>
          </div>
        </Container>
        }
        <Preloader load={load} />
        {/* <Language /> */}
        <Routes>
          {isLanguageSelected ? <Route path="/LT" element={<LT language={selectedLanguage}/>} /> : null }
          {isLanguageSelected ? <Route path="/MOS" element={<MOS language={selectedLanguage}/>} /> : null }
          {isLanguageSelected ? <Route path="/PSL" element={<PSL language={selectedLanguage}/>} /> : null }
          {isLanguageSelected ? <Route path="/VP" element={<VP language={selectedLanguage}/>} /> : null }
          {isLanguageSelected ? <Route path="/TMS" element={<TMS language={selectedLanguage}/>} /> : null }
          {isLanguageSelected ? <Route path="/LR" element={<LR language={selectedLanguage}/>} /> : null }
          {/* <Route path="/DTMS" element={<DashboardTMS language={selectedLanguage}/>} />
          <Route path="/DPSL" element={<DashboardPSL language={selectedLanguage}/>} />
          <Route path="/DLR" element={<DashboardLR language={selectedLanguage}/>} /> */}
          {/* <Route path="/DLT" element={<DashboardLT language={selectedLanguage}/>} /> */}
          {/* <Route path="/DMOS" element={<DashboardMOS language={selectedLanguage}/>} /> */}
          {/* <Route path="/DVP" element={<DashboardVP language={selectedLanguage}/>} /> */}
          <Route path="/Dtest" element={<Test language={selectedLanguage}/>} />
          {isLanguageSelected ? <Route path="/" element={<Dashboard language={selectedLanguage}/>} /> : null }
          {/* <Route path="/D" element={<Dashboard3 language={selectedLanguage}/>} /> */}
          {isLanguageSelected ? <Route path="/Schedule" element={<Schedule language={selectedLanguage}/>} /> : null }
          {isLanguageSelected ? <Route path="/about" element={<About language={selectedLanguage}/>} /> : null }
          {isLanguageSelected ? <Route path="/TandC" element={<TandC language={selectedLanguage}/>} /> : null }
          {isLanguageSelected ? <Route path="/Peak" element={<PeakHour language={selectedLanguage}/>} /> : null }
          {isLanguageSelected ? <Route path="/NPeak" element={<NonPeakHour language={selectedLanguage}/>} /> : null }
          
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
        {isLanguageSelected ? <Footer language={selectedLanguage} /> : null }
      </div>
    </Router>
  );
}

export default App;
