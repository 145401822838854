import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import MaskImg from "../../Assets/artist-img.svg";
import background from "../../Assets/CKCII_Mountain/Lam-Tsuen-Country-Park.jpg";
function LamTsuen({language}) {
  const body_zh1_1 = "林村位於新界北部，橫跨大埔區、北區和元朗區,  最高點桂角山，達585米，山麓以南是錦田盤地。";
  const body_zh1_2 = "無論由粉嶺或元朗出發，前往林村郊野公園的遠足路徑都較難行。雖然要沿著崎嶇的山徑，登上陡峭的山坡，但沿途可欣賞到優美的風景及廣闊的景觀。由山脊上可眺望元朗平原、南生圍、米埔與后海灣，以至遙遠的深圳。山腳下則是錦綉花園及錦田。";
  const body_zh2_1 = "林村以林村許願樹作為主題，並以綠色作為主要色調，以回應樹木的象徵意義。";
  const body_zh2_2 = "為了呼應許願樹的主題，林村的設計以綠色作為主要色調。綠色是大自然和生命的象徵，它與樹木的生長和繁茂相呼應。這種色調的運用使得整個林村充滿了生機和活力，同時也彰顯了許願樹所代表的希望和祝福。";
  const body_en1_1 = "Lam Tsuen is located in the northern New Territories, spanning across the Tai Po, North, and Yuen Long districts. Its highest point is Kwai Kot Mountain, reaching 585 meters, with the Kam Tin basin lying to the south of the foothills.";
  const body_en1_2 = "Whether starting from Fanling or Yuen Long, the hiking trails leading to the Lam Tsuen Country Park are quite challenging. While the rugged mountain paths and steep slopes need to be traversed, the journey rewards hikers with beautiful scenery and expansive views. From the mountain ridges, one can gaze out over the Yuen Long Plain, the San Sang Wai, Mai Po, and Deep Bay, as well as the distant Shenzhen. At the foot of the mountains lie the picturesque Kam Sap Gardens and Kam Tin.";
  const body_en2 = "Lam Tsuen is themed around the Lam Tsuen Wishing Tree, using green as the primary color to reflect the symbolic meaning of the tree. Green represents nature and life, echoing the growth and abundance of trees. This color choice fills Lam Tsuen with vitality and energy while highlighting the hope and blessings represented by the wishing tree.";
  return (
    <div>

{language === 'zh' ? (
              <Container className="home-content" >
                <Row className="background-white" >
                  <Col className="popup-header-zh">
                  林村
                  </Col>
                  <div className="box" style={{marginLeft:12, backgroundImage: `url(${background})`}}>
                      <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
                    </div>
                    <p className="about-artist-body">
                    簡介
                    </p>
                      <p className="about-artist-body">{body_zh1_1}<br/><br/>{body_zh1_2}</p>
                    <p className="about-artist-body">
                    主色調：綠色
                    </p>
                    <p className="about-artist-body">{body_zh2_1}<br/><br/>{body_zh2_2}</p>
                </Row>
              </Container>
            ) : (
              <Container className="home-content" >
                <Row className="background-white" >
                  <Col className="popup-header-zh">
                  LAM TSUEN
                  </Col>
                  <div className="box" style={{marginLeft:12, backgroundImage: `url(${background})`}}>
                      <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
                    </div>
                    <p className="about-artist-body">
                    Introduction
                    </p>
                      <p className="about-artist-body">{body_en1_1}<br/><br/>{body_en1_2}</p>
                    <p className="about-artist-body">
                    COLOR：GREEN
                    </p>
                    <p className="about-artist-body">{body_en2}</p>
                </Row>
              </Container>
              )}
      </div>
  );
}

export default LamTsuen;