import { Container, Row, Col} from "react-bootstrap";
import React, { useState, useEffect , Suspense , useRef ,Button} from "react";
import { motion } from "framer-motion";

function DashboardTMS(props) {
  const BoxBGC = "#69707b";
  const BoxHeaderC = "#BDEEF2";
  const BoxHeader_zh = "大帽山";
  const BoxHeader_en = "TAI MO SHAN";
  const HEIGHT = "957m";
  const COLOR_zh = "淡灰、冷灰和石灰綠";
  const COLOR_en = "BLUE+GREY";
  const BODY1_zh = "大帽山昂然屹立新界中部高達957米，是香港最高峰。";
  const BODY1_en = "Tai Mo Shan stands tall and proud at the center of the New Territories, reaching a height of 957 meters, making it the highest peak in Hong Kong.";
  const BODY2_zh = "";
  const BODY2_en = "";
  const [isCurrent, setIsCurrent] = useState(true);


  return (
    <div>
      {props.language === 'zh' ? (
        <Container >
          <Row >
            <div>
            <Col style={{ paddingTop:67 }}>
                <motion.div className=" dashboard-box " style={{backgroundColor: BoxBGC }}
                    initial={{ opacity: 0, x:100 }}
                    animate={{ opacity: 1, x:0 }}
                    transition={{ type: "spring", duration: 1, delay: 0.5, ease: [0, 0.71, 0.2, 1.01] }}>
                      {isCurrent ?
                      <section>
                        <div className="current icon-flex-cur" style={{color: "#1d1d1b"}}>
                        <div className="current-div" style={{display: "none"}}>
                            現場
                          </div>
                        </div>
                      </section>
                      : null }
                </motion.div>
                <motion.h1 className="dashboard-img-header" style={{color: BoxHeaderC }}
                    initial={{ opacity: 0, x:100 }}
                    animate={{ opacity: 1, x:0 }}
                    transition={{ type: "spring", duration: 1, delay: 0.6, ease: [0, 0.71, 0.2, 1.01] }}>
                    {BoxHeader_zh}
                </motion.h1>
            </Col>              
            </div>
            <Row>
              {/* Start Here */}
                <Row style={{paddingBottom:"35px", paddingTop:"35px"}}>
                  <Col>
                  <div className="left-border">
                      <Row className="dashboard-header">
                        高度
                      </Row>
                      <Row className="dashboard-grey">
                      {HEIGHT}
                      </Row>
                      <Row className="home-about-body">
                      {BODY1_zh}
                      </Row>
                  </div>
                  </Col> 
                  <Col>
                  <div className="left-border">
                      <Row className="dashboard-header">
                        主色調
                      </Row>
                      <Row className="dashboard-grey">
                        {COLOR_zh}
                      </Row>
                      <Row className="home-about-body">
                      {BODY2_zh}
                      </Row>
                  </div>
                  </Col> 
                </Row>
                {/* End Here */}
            </Row>
          </Row>
        </Container>
      ) : (
        <Container >
          <Row >
            <div>
            <Col style={{ paddingTop:67 }}>
                <motion.div className=" dashboard-box " style={{backgroundColor: BoxBGC }}
                    initial={{ opacity: 0, x:100 }}
                    animate={{ opacity: 1, x:0 }}
                    transition={{ type: "spring", duration: 1, delay: 0.5, ease: [0, 0.71, 0.2, 1.01] }}>
                      {isCurrent ?
                      <section>
                        <div className="current icon-flex-cur" style={{color: "#1d1d1b"}}>
                        <div className="current-div" style={{display: "none"}}>
                          Current
                          </div>
                        </div>
                      </section>
                      : null }
                </motion.div>
                <motion.h1 className="dashboard-img-header-en" style={{color: BoxHeaderC }}
                    initial={{ opacity: 0, x:100 }}
                    animate={{ opacity: 1, x:0 }}
                    transition={{ type: "spring", duration: 1, delay: 0.6, ease: [0, 0.71, 0.2, 1.01] }}>
                    {BoxHeader_en}
                </motion.h1>
            </Col>              
            </div>
            <Row>
              {/* Start Here */}
                <Row style={{paddingBottom:"35px", paddingTop:"35px"}}>
                  <Col>
                  <div className="left-border">
                      <Row className="dashboard-header">
                      HEIGHT
                      </Row>
                      <Row className="dashboard-grey">
                      {HEIGHT}
                      </Row>
                      <Row className="home-about-body">
                      {BODY1_en}
                      </Row>
                  </div>
                  </Col> 
                  <Col>
                  <div className="left-border">
                      <Row className="dashboard-header">
                        COLOUR
                      </Row>
                      <Row className="dashboard-grey">
                        {COLOR_en}
                      </Row>
                      <Row className="home-about-body">
                      {BODY2_en}
                      </Row>
                  </div>
                  </Col> 
                </Row>
                {/* End Here */}
            </Row>
          </Row>
        </Container>
      )}
      </div>
    );
  }

export default DashboardTMS;
