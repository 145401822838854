import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Model_LT(props) {
  const { nodes, materials } = useGLTF('/LTGLTF.gltf')
  return (
    <group {...props} dispose={null}>
      <group position={[0.2, 0, 0]} rotation={[0.5, 0.4, 0.2]} scale={0.0015}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.EXPORT_GOOGLE_SAT_WM007.geometry}
          material={materials.LT_Top}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.EXPORT_GOOGLE_SAT_WM007_1.geometry}
          material={materials.LT_Bottom}
        />
      </group>
    </group>
  )
}
export default Model_LT;