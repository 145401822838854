import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { color } from "framer-motion";

function NavBar({load,language}) {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 5) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return load && language !== null ? null :(
    <div>
    {language === 'zh' ? (
      <Navbar
        expanded={expand}
        fixed="top"
        expand="md"
        className={`${navColour ? "sticky" : "navbar"} ${expand ? "navbar" : "navbar-trans"}`}
      >
        <Container>
          <Navbar.Brand href="/" className={`"d-flex" ${expand ? "navbar-header" : "navbar-header-grey"}`}>
            {/* <img src={logo} className="img-fluid logo" alt="brand" /> */}
            CKC ll MEDIA WALL
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => {
              updateExpanded(expand ? false : "expanded");
            }}
            className={expand ? "navbar-toggler" : "navbar-toggler-grey"}
          >
            <span style={{backgroundColor: expand ? "#f1f1f1" : "#1d1d1b"}}></span>
            <span style={{backgroundColor: expand ? "#f1f1f1" : "#1d1d1b"}}></span>
            <span style={{backgroundColor: expand ? "#f1f1f1" : "#1d1d1b"}}></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto " defaultActiveKey="#home" style={{ fontSize: "5"}}>
              <Nav.Item>
                <Nav.Link style={{ lineHeight:"130%", fontSize: "2rem" , letterSpacing: "3px"}} as={Link} to="/" onClick={() => updateExpanded(false)}>
                  儀表板
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link style={{ lineHeight:"130%", fontSize: "2rem" , letterSpacing: "3px"}} as={Link} to="/about" onClick={() => updateExpanded(false)}>
                  關於 THE MEDIA WALL
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link style={{ lineHeight:"130%", fontSize: "2rem" , letterSpacing: "3px"}} as={Link} to="/Schedule" onClick={() => updateExpanded(false)}>
                  時間表
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link style={{ lineHeight:"130%", fontSize: "2rem", letterSpacing: "3px", visibility:"hidden"}} as={Link} to="/TandC" onClick={() => updateExpanded(false)}>
                  T&C
                </Nav.Link>
              </Nav.Item>
              <div style={{paddingBottom: 50}}/>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    ) : (
      <Navbar
        expanded={expand}
        fixed="top"
        expand="md"
        className={`${navColour ? "sticky" : "navbar"} ${expand ? "navbar" : "navbar-trans"}`}
      >
        <Container>
          <Navbar.Brand href="/" className={`"d-flex" ${expand ? "navbar-header" : "navbar-header-grey"}`}>
            {/* <img src={logo} className="img-fluid logo" alt="brand" /> */}
            CKC ll MEDIA WALL
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => {
              updateExpanded(expand ? false : "expanded");
            }}
            className={expand ? "navbar-toggler" : "navbar-toggler-grey"}
          >
            <span style={{backgroundColor: expand ? "#f1f1f1" : "#1d1d1b"}}></span>
            <span style={{backgroundColor: expand ? "#f1f1f1" : "#1d1d1b"}}></span>
            <span style={{backgroundColor: expand ? "#f1f1f1" : "#1d1d1b"}}></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                SHOW DASHBOARD
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link as={Link} to="/about" onClick={() => updateExpanded(false)}>
                ABOUT THE MEDIA WALL
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link as={Link} to="/Schedule" onClick={() => updateExpanded(false)}>
                SCHEDULE
              </Nav.Link>
            </Nav.Item>

            <Nav.Item >
              <Nav.Link style={{ visibility:"hidden"}} as={Link} to="/TandC" onClick={() => updateExpanded(false)}>
                T&C
              </Nav.Link>
            </Nav.Item>
            <div style={{paddingBottom: 50}}/>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    )}
    </div>
  );
}

export default NavBar;

