import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import AboutArtist from "./AboutArtist";
import AboutTeam from "./AboutTeam";
import AboutArchitect from "./AboutArchitect";
import Terrain2 from "../terrain2";
function About({language}) {
  const subtitle_zh = "";
  const subtitle_en = "";
  const body_zh1 = "我們的位置位於CKCII，被現代化的建築、繁忙的街道和人群所環繞。儘管身處都市喧囂之中，我們的視線仍然可以穿透前方和後方大自然景象。";
  const body_zh2 = "我們挑選了六座山脈，以CKCII為中心，分別是大帽山、馬鞍山、八仙嶺、林村、太平山和獅子山。這些山脈的影像呈現是基於它們在高度上的變化速度，我們可以觀察到整座山的橫切面，展現出山脊線的變化。同時，透過背景加入日出和日落的元素，背後的光條呈現出不同程度的陽光照射。";
  const body_zh3 = "這樣將現代城市生活與大自然美景相結合的場景，讓我們在繁忙的日常中找到片刻的寧靜和滿足感。它提醒著我們大自然的偉大和人與自然之間的聯繫，同時也讓我們更加珍惜我們所擁有的城市環境。不論是在忙碌的白天還是寧靜的夜晚，這些景觀都為我們帶來視覺上的享受和內心的寧靜。";
  const body_en1 = "Our location is situated within CKC II, surrounded by modernized buildings, busy streets, and crowds of people. Despite being immersed in the urban hustle and bustle, our line of sight can still penetrate the natural landscapes in the foreground and background.";
  const body_en2 = "We have selected six mountain ranges, with CKC II at the center - they are Tai Mo Shan, Ma On Shan, the Eight Immortals Ridge, Lam Tsuen, Tai Ping Shan, and Lion Rock. The visual representations of these mountain ranges are based on the varying rates of change in their elevations, allowing us to observe the cross-sections of the entire mountains and the changes in their ridgelines. Additionally, by incorporating elements of sunrise and sunset in the background, the light beams behind them convey different degrees of sunlight exposure.";
  const body_en3 = "This amalgamation of modern urban life and the beauty of nature provides us with moments of tranquility and fulfillment amidst our busy daily lives. It reminds us of the grandeur of nature and the connection between humanity and the natural world, while also allowing us to appreciate the urban environment we inhabit. Whether in the busy daytime or the serene nighttime, these landscapes offer us visual enjoyment and inner peace.";
  return (
    <div>
    {language === 'zh' ? (
    <section style={{paddingTop:"67px" , paddingBottom:"10vw" , maxWidth: "100%" , overflowX: "hidden"}}>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
            <Row className="background-black">
              <Col className="about-header" >
              關於
              </Col>
              <Row>
                <Col style={{paddingLeft:0, paddingRight:0}}>
                  <p className="about-body">{subtitle_zh}</p>
                  <p className="about-body" id="content">{body_zh1}<br/>{body_zh2}<br/>{body_zh3}</p>
                </Col>
              </Row>
            </Row>
        </Container>
        <AboutArchitect language={language}/>
        <AboutTeam language={language}/>
        <Row className="background-white" style={{paddingBottom:0}}>
        <Container>
          <div className="quote" style={{marginLeft:0, marginRight:0, display:"none"}}>A</div>
          </Container>
          <Container ><Terrain2 /></Container>
          </Row>
      </Container>
    </section>
  ) : (
    <section style={{paddingTop:"67px" , paddingBottom:"10vw" , maxWidth: "100%" , overflowX: "hidden"}}>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
            <Row className="background-black">
              <Col className="about-header" >
            About
            </Col>
            <Row>
              <Col style={{paddingLeft:0, paddingRight:0}}>
                <p className="about-body">{subtitle_en}</p>
                <p className="about-body">{body_en1}</p><p className="about-body">{body_en2}</p><p className="about-body">{body_en3}</p>
              </Col>
            </Row>
          </Row>
      </Container>
      <AboutArchitect />
      <AboutTeam />
      <Row className="background-white" style={{paddingBottom:0}}>
      <Container>
        <div className="quote" style={{marginLeft:0, marginRight:0, display:"none"}}>B</div>
        </Container>
        <Container><Terrain2 /></Container>
        </Row>
    </Container>
  </section>
   )}
  </div>
  );
}

export default About;