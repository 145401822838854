import React, { useState, useEffect} from "react";
import "../style.css";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row} from "react-bootstrap";
import Terrain from "./terrain";

// not currently in use

function Language() {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [isLanguageSelected,setIsLanguageSelected] = useState(null);

  useEffect(() => {
    if (selectedLanguage === 'zh' ) {// Update the page language based on the selected language
      setIsLanguageSelected(true);
      console.log(isLanguageSelected);
    }else if (selectedLanguage === 'en' ) {// Update the page language based on the selected language
      setIsLanguageSelected(true);
      console.log(isLanguageSelected);
    }
    // return () =>{};
  }, []);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setIsLanguageSelected(true);
  };
  
  return (
    <div>
      {isLanguageSelected ? null:
      <Container>
      <div className="terrain-background" style={{ paddingTop: 100 }}>
        <Row>
          <div className="terrain" style={{ maxWidth: 350 }}>
            <Terrain />
          </div>
        </Row >
        <div className="quote-lan">
          <button className="lan" onClick={() => handleLanguageChange("zh")} herf="/about">
            <div>中文</div>
          </button>
          <br>|</br>
          <button className="lan" onClick={() => handleLanguageChange("en")} herf="/about">
            English
          </button>
        </div>
        <div className="lan-header" style={{ fontSize: 70, paddingLeft: 40 }}>
          CKCLL <br /> MEDIA WALL
        </div>
      </div>
    </Container>}
    </div>
  );
}

export default Language;