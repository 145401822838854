import { Container, Row, Col} from "react-bootstrap";
import React, { useState, useEffect , Suspense , useRef ,Button} from "react";
import { motion } from "framer-motion";

function Dashboard3(props) {
  const BoxBGC = "#5a783b";
  const BoxHeaderC = "#f1f1f1";
  const BoxHeader_zh = "太平山";
  const BoxHeader_en = "VICTORIA PEAK";
  const HEIGHT = "552m";
  const COLOR_zh = "綠色＋白色";
  const COLOR_en = "GREEN+WHITE";
  const BODY1_zh = "太平山位於香港島西部，屬於中西區的一部分，高552米。";
  const BODY1_en = "Victoria Peak is located in the western part of Hong Kong Island, belonging to the Central and Western District, standing at 552 meters.";
  const BODY2_zh = "XXXXXXXXXX";
  const BODY2_en = "XXXXXXXXXX";
  const [isCurrent, setIsCurrent] = useState(true);

  
  return (
  <div>
    {props.language === 'zh' ? (
      <Container >
        <Row >
          <div>
          <Col style={{ paddingTop:67 }}>
              <motion.div className=" dashboard-box " style={{backgroundColor: BoxBGC }}
                  initial={{ opacity: 0, x:100 }}
                  animate={{ opacity: 1, x:0 }}
                  transition={{ type: "spring", duration: 1, delay: 0.5, ease: [0, 0.71, 0.2, 1.01] }}>
                    {isCurrent ?
                    <section>
                      <div className="current icon-flex-cur" style={{color: "#1d1d1b"}}>
                        <div className="current-div">
                          現場
                        </div>
                      </div>
                    </section>
                    : null }
              </motion.div>
              <motion.h1 className="dashboard-img-header" style={{color: BoxHeaderC }}
                  initial={{ opacity: 0, x:100 }}
                  animate={{ opacity: 1, x:0 }}
                  transition={{ type: "spring", duration: 1, delay: 0.6, ease: [0, 0.71, 0.2, 1.01] }}>
                  {BoxHeader_zh}
              </motion.h1>
          </Col>              
          </div>
          <Row>
            {/* Start Here */}
              <Row style={{paddingBottom:"35px", paddingTop:"35px"}}>
                <Col>
                <div className="left-border">
                    <Row className="dashboard-header">
                      高度
                    </Row>
                    <Row className="dashboard-grey">
                    {HEIGHT}
                    </Row>
                    <Row className="home-about-body">
                    {BODY1_zh}
                    </Row>
                </div>
                </Col> 
                <Col>
                <div className="left-border">
                    <Row className="dashboard-header">
                      主色調
                    </Row>
                    <Row className="dashboard-grey">
                      {COLOR_zh}
                    </Row>
                    <Row className="home-about-body">
                    {BODY2_zh}
                    </Row>
                </div>
                </Col> 
              </Row>
              {/* End Here */}
          </Row>
        </Row>
      </Container>
    ) : (
      <Container >
        <Row >
          <div>
          <Col style={{ paddingTop:67 }}>
              <motion.div className=" dashboard-box " style={{backgroundColor: BoxBGC }}
                  initial={{ opacity: 0, x:100 }}
                  animate={{ opacity: 1, x:0 }}
                  transition={{ type: "spring", duration: 1, delay: 0.5, ease: [0, 0.71, 0.2, 1.01] }}>
                    {isCurrent ?
                    <section>
                      <div className="current icon-flex-cur" style={{color: "#1d1d1b"}}>
                        <div className="current-div">
                          Current
                        </div>
                      </div>
                    </section>
                    : null }
              </motion.div>
              <motion.h1 className="dashboard-img-header" style={{color: BoxHeaderC }}
                  initial={{ opacity: 0, x:100 }}
                  animate={{ opacity: 1, x:0 }}
                  transition={{ type: "spring", duration: 1, delay: 0.6, ease: [0, 0.71, 0.2, 1.01] }}>
                  {BoxHeader_en}
              </motion.h1>
          </Col>              
          </div>
          <Row>
            {/* Start Here */}
              <Row style={{paddingBottom:"35px", paddingTop:"35px"}}>
                <Col>
                <div className="left-border">
                    <Row className="dashboard-header">
                    HEIGHT
                    </Row>
                    <Row className="dashboard-grey">
                    {HEIGHT}
                    </Row>
                    <Row className="home-about-body">
                    {BODY1_en}
                    </Row>
                </div>
                </Col> 
                <Col>
                <div className="left-border">
                    <Row className="dashboard-header">
                      主色調
                    </Row>
                    <Row className="dashboard-grey">
                      {COLOR_en}
                    </Row>
                    <Row className="home-about-body">
                    {BODY2_en}
                    </Row>
                </div>
                </Col> 
              </Row>
              {/* End Here */}
          </Row>
        </Row>
      </Container>
    )}
    </div>
  );
}

export default Dashboard3;
