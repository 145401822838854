import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import MaskImg from "../../Assets/artist-img.svg";
import background from "../../Assets/CKCII_Mountain/CP_photo_LionRock_s.jpg";
function LionRock({language}) {
  const body_zh1_1 = "獅子山位於九龍和新界之間，九龍群山之一， 高495米，山嶺之西崖石嶙峋，形如獅子俯伏，雄視香江，見證著香港由漁村演變成大都會。獅子山精神，泛指香港人努力、勤奮、自強不息、刻苦耐勞、同舟共濟、不屈不撓的拼搏精神。";
  const body_zh1_2 = "獅子山東西兩面各有一條古老石徑。西面的一條由九龍橫頭磡蜿蜒直上獅子山坳，然後通往沙田，這條石徑已由漁護署修復，方便登山人士使用。另一條石徑貫通沙田坳，連接九龍慈雲山與沙田作壆坑。清朝時，這兩條石徑是沙田與九龍之間的主要通道。";
  const body_zh2 = "獅子山的色調主要以大地色為基調，這個設計靈感源於獅子的自然色彩以及廣闊原野的色調。獅子山擁有一種深沉的棕色、黃褐色和金色調，這些色彩營造出一種堅毅、威武的氛圍，正好象徵了獅子的威猛形象。此外，這些大地色調也呼應了原野的自然風貌，勾勒出山脈的輪廓和地形，使獅子山更加與周圍的環境相融合。";
  const body_en1_1 = "Lion Rock is located between Kowloon and the New Territories, as one of the Kowloon mountain ranges. Standing at 495 meters in height, the western ridge of the mountain features rugged cliffs that resemble a crouching lion overlooking Victoria Harbor. This mountain has witnessed Hong Kong's transformation from a fishing village to a metropolitan city.";
  const body_en1_2 = "The \"Lion Rock Spirit\" refers to the qualities of the Hong Kong people - hardworking, diligent, self-reliant, persevering, mutual assistance, and unyielding determination.";
  const body_en1_3 = "There are two ancient stone paths on the eastern and western sides of Lion Rock. The western path winds from Hung Hom in Kowloon up to the Lion Rock saddle, and then continues on to Sha Tin. This path has been restored by the Agriculture, Fisheries and Conservation Department, making it accessible for hikers. The other path runs through the Sha Tin saddle, connecting Kowloon Tsz Wan Shan and Sha Tin Wo Hang. During the Qing dynasty, these two paths were the main routes between Sha Tin and Kowloon.";
  const body_en2 = "The color palette of Lion Rock primarily features earth tones, inspired by the natural colors of lions and the hues of the vast plains. It boasts deep browns, tawny yellows, and golden tones, creating a resilient and majestic atmosphere that perfectly symbolizes the powerful image of the lion. Additionally, these earth tones resonate with the natural landscape of the plains, outlining the contours and terrain of the mountain, enhancing Lion Rock's integration with its surrounding environment.";
  return (
    <div>

{language === 'zh' ? (
              <Container className="home-content" >
                <Row className="background-white" >
                  <Col className="popup-header-zh">
                  獅子山
                  </Col>
                    <div className="box" style={{marginLeft:12, backgroundImage: `url(${background})`}}>
                      <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
                    </div>
                    <p className="about-artist-body">
                    簡介
                    </p>
                      <p className="about-artist-body">{body_zh1_1}<br/><br/>{body_zh1_2}</p>
                    <p className="about-artist-body">
                    主色調：棕色、黃褐色和金色
                    </p>
                    <p className="about-artist-body">{body_zh2}</p>
                </Row>
              </Container>
            ) : (
              <Container className="home-content" >
                <Row className="background-white" >
                  <Col className="popup-header-zh">
                  LION ROCK
                  </Col>
                    <div className="box" style={{marginLeft:12, backgroundImage: `url(${background})`}}>
                      <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
                    </div>
                    <p className="about-artist-body">
                    Introduction
                    </p>
                      <p className="about-artist-body">{body_en1_1}<br/><br/>{body_en1_2}<br/><br/>{body_en1_3}</p>
                    <p className="about-artist-body">
                    COLOR：YELLOW ＋ BROWN（EARTH TONES）
                    </p>
                    <p className="about-artist-body">{body_en2}</p>
                </Row>
              </Container>
              )}
      </div>
  );
}

export default LionRock;