import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import MaskImg from "../../Assets/artist-img.svg";
import background from "../../Assets/CKCII_Mountain/CP_photo_PSL_OverlookingMaTauFung_fromPSLNatureTrail_s.jpg";
function PatSinLeng({language}) {
  const body_zh1 = "八仙嶺是香港新界東北部的一條山脈，位於大埔吐露港北岸，山脈全長約1公里，由8個高度相約的山峰組成，以八仙來命名，由東面數起為何仙姑（仙姑峰）、韓湘子（湘子峰）、藍采和（采和峰）、曹國舅（曹舅峰）、鐵拐李（拐李峰）、張果老（果老峰）、漢鍾離（鍾離峰）和呂洞賓（純陽峰）。八仙嶺的主峰為「純陽峰」，高590米，為群峰之首，可遠眺新界東北水天一色的景觀。";
  const body_zh2 = "八仙嶺在春季，整個山區都被嫣紅的杜鵑和其他開花的灌木植物點綴得如詩如畫。因此，在設計中選擇了以白色和粉色調為主，以呼應這些美麗的杜鵑花。白色和粉色還能夠突顯八仙嶺的自然環境和景觀。這些色彩與山谷中茂密的樹木和綠蔭相映成趣，形成了一幅和諧而富有層次感的畫面。";
  const body_en1 = "The Eight Immortals Ridge (Ba Xian Ling) is a mountain range in the northeastern New Territories of Hong Kong, located along the northern shore of Tolo Harbour in Tai Po. The mountain range is approximately 1 kilometer long and consists of 8 peaks of roughly equal height, named after the Eight Immortals of Taoism. From east to west, they are: He Xian'gu (Xian'gu Peak), Han Xiangzi (Xiangzi Peak), Lan Caihe (Caihe Peak), Cao Guojiu (Guojiu Peak), Li Tieguai (Tieguai Peak), Zhang Guolao (Guolao Peak), Han Zhongli (Zhongli Peak), and Lü Dongbin (Chun Yang Peak).";
  const body_en2 = "In spring, the entire area of Bai Seng Ling is adorned with vibrant red azaleas and other flowering shrubs, creating a picturesque landscape. Therefore, the design predominantly features white and pink tones to reflect the beauty of these azaleas. The use of white and pink also enhances the natural environment and scenery of Bai Seng Ling. These colors beautifully contrast with the dense trees and greenery in the valley, forming a harmonious and layered image.";
  return (
    <div>

{language === 'zh' ? (
          <Container className="home-content" >
            <Row className="background-white" >
              <Col className="popup-header-zh">
              八仙嶺
              </Col>
                <div className="box" style={{marginLeft:12, backgroundImage: `url(${background})`}}>
                  <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
                </div>
                <p className="about-artist-body">
                簡介
                </p>
                  <p className="about-artist-body">{body_zh1}</p>
                <p className="about-artist-body">
                主色調：白色和粉色
                </p>
                <p className="about-artist-body">{body_zh2}</p>
            </Row>
          </Container>
        ) : (
          <Container className="home-content" >
            <Row className="background-white" >
              <Col className="popup-header-zh">
              PAT SIN LENG
              </Col>
                <div className="box" style={{marginLeft:12, backgroundImage: `url(${background})`}}>
                  <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
                </div>
                <p className="about-artist-body">
                Introduction
                </p>
                  <p className="about-artist-body">{body_en1}</p>
                <p className="about-artist-body">
                COLOR：PINK ＋ WHITE
                </p>
                <p className="about-artist-body">{body_en2}</p>
            </Row>
          </Container>
          )}
      </div>
  );
}

export default PatSinLeng;