import React from "react";
import Terrain from "./terrain";
import { Container, Row} from "react-bootstrap";
import Typewriter from "typewriter-effect";

function Pre(props) {
  // return <div id={props.load ? "preloader" : "preloader-none"}></div>;
    // return props.load ? <div className="terrain" style={{marginTop:50}}><Terrain /></div> : null;
    return props.load ?(
      <span >
      <Container className="loading">
      <div >
          <div className="terrain" >
            <Terrain />
          </div>
          <div className="quote-lan">
          LOADING ...
        {/* <Typewriter options=
          {{
          strings: ["Loading ..."],
          autoStart: true,
          loop: false,
          deleteSpeed: false,
          }}
        /> */}
        </div>
        <div style={{margin:"auto"}}>
          <div className="lan-header">CKC ll <br/>MEDIA WALL</div>
        </div>
      </div>
    </Container>
    </span>
    ):null;
}

export default Pre;
