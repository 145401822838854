import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import MaskImg from "../../Assets/artist-img2.svg";
import background from "../../Assets/About/ttm.jpg";

function AboutTeam ({language}) {
  return (
    <div>
{language === 'zh' ? (
      <Container className="home-content">
        <Row className="background-white" >
          <Col className="about-artist-header">
          <div className="about-artist-subheader">
          媒體藝術團隊
          </div>
          THINGS THAT MOVE
          </Col>
            <div className="box" style={{marginLeft:12, backgroundImage: `url(${background})`}}>
              <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
            </div>
              <p className="about-artist-body">
              郁下郁下有限公司是一家創意工作室，提供藝術、設計和技術服務，並通過實驗和研究保持新鮮感。我們為藝術和創意產業提供度身定制的解決方案和研發服務，專注於融合實體和數碼媒介，模糊藝術、設計和工程之間的界限，為創意、文化機構和商業品牌創造新的體驗。公司專注於動力機械裝置、互動機器人、沉浸式和數碼裝置，團隊同時自發創作藝術和研究作品，探索應用層面，提供全新的體驗。在2023年，我們成為香港科學園創科培育三年計劃的一員，繼續研究實體與數碼媒介的界限，帶來新的角度與體驗。<br/>創辦人黃智銓的作品獲邀於全球超過10個國家的展覽，包括林茨電子藝術節（奧地利）、WRO媒體藝術雙年展（波蘭）、雅典錄像藝術節（希臘）、Phoenix電影及藝術中心（英國）、紐約大學阿布扎比分校畫廊（阿聯酋）、BIAN國際媒體藝術雙年展（加拿大）、LOOP巴塞羅那（西班牙）、FILE電子藝術節（巴西）、404藝術節（阿根廷）、首爾國際媒體藝術節（韓國）、藝術門（香港）等。他獲得第三屆國際新晉藝術家獎（阿聯酋）Golden 15提名，並於2014年獲頒香港藝術發展局藝術新秀獎（媒體藝術組別），以及被選為「Bloomberg數位藝術培育計劃2013」（香港）參加者。黃智銓現為獨立藝術家和設計師，曾於十下創作擔任創意工匠（Creative Technologist），並在加拿大蒙特利爾的Concordia University修讀碩士，主修雕塑。他於2015年在香港城市大學創意媒體學院擔任兼任講師，並於2011年獲得香港城市大學創意媒體文學士（榮譽）學位。</p>
              
              <p className="about-artist-body">媒體藝術家： 黃智銓</p>
              <p className="about-artist-body">特別聲音鳴謝：簡僖進  *創辦人、田野錄音師、聲音藝術家、音響設計師*  </p>
        </Row>
      </Container>
    ) : (
      <Container className="home-content">
        <Row className="background-white" >
          <Col className="about-artist-header">
          <div className="about-artist-subheader">
          MEDIA ARTIST TEAM
          </div>
          THINGS THAT MOVE
          </Col>
            <div className="box" style={{marginLeft:12, backgroundImage: `url(${background})`}}>
              <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
            </div>
              <p className="about-artist-body">Things That Move is a creative studio that provides art, design, and technology services, maintaining freshness through experimentation and research. We offer customized solutions and R&D services for the arts and creative industries, focusing on the integration of physical and digital media, blurring the lines between art, design, and engineering to create new experiences for creative and cultural institutions as well as commercial brands. Our emphasis is on kinetic installations, interactive robots, immersive and digital devices, while our team also spontaneously creates art and research works to explore applications and provide innovative experiences. In 2023, we became a part of the three-year innovation and technology incubation program at Hong Kong Science Park, continuing to investigate the boundaries between physical and digital media, bringing new perspectives and experiences. <br/>
Founder Kenny Wong has had his works exhibited in over 10 countries worldwide, including the Linz Electronic Arts Festival (Austria), the WRO Media Art Biennale (Poland), the Athens Video Art Festival (Greece), the Phoenix Art and Film Center (UK), the New York University Abu Dhabi Gallery (UAE), the BIAN International Media Art Biennale (Canada), LOOP Barcelona (Spain), FILE Electronic Art Festival (Brazil), the 404 Art Festival (Argentina), the Seoul International Media Art Festival (South Korea), and Art Basel (Hong Kong), among numerous international exhibitions. He received a Golden 15 nomination for the Third International Emerging Artist Award (UAE) and was awarded the Hong Kong Arts Development Council's Arts Newcomer Award (Media Arts Category) in 2014, as well as being selected for the Bloomberg Digital Art Incubation Program 2013 (Hong Kong). Huang Zhiquan is currently an independent artist and designer, having served as a Creative Technologist at Tiansha, and studied for his master's degree in sculpture at Concordia University in Montreal, Canada. In 2015, he became a part-time lecturer at the School of Creative Media at City University of Hong Kong, where he earned his Bachelor of Arts (Hons) in Creative Media in 2011.
</p>
              <p className="about-artist-body">Media Artist: Kenny Wong Chi-Chuen</p>
              <p className="about-artist-body">Special thanks to: Ak Kan  *Founder, Field Recordist, Sound Artist, Sound Designer*</p>
        </Row>
      </Container>
    )}
    </div>
  )
}
export default AboutTeam;