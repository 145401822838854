import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Model_TMS(props) {
  const { nodes, materials } = useGLTF('/TMSGLTF.gltf')
  return (
    <group {...props} dispose={null}>
      <group position={[0.2, -0.8, 0]} rotation={[0.6, 0.4, 0.1]} scale={0.00299}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.EXPORT_GOOGLE_SAT_WM.geometry}
          material={materials.TMS_top}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.EXPORT_GOOGLE_SAT_WM_1.geometry}
          material={materials.TMS_bottom}
        />
      </group>
    </group>
  )
}

export default Model_TMS;