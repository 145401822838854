import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Model_PSL(props) {
  const { nodes, materials } = useGLTF('/PSLGLTF.gltf')
  return (
    <group {...props} dispose={null}>
      <group position={[0.2, -1, 0]} rotation={[0.5, 0.4, 0.2]} scale={0.0019}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.EXPORT_GOOGLE_SAT_WM005.geometry}
          material={materials.PSL_Top}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.EXPORT_GOOGLE_SAT_WM005_1.geometry}
          material={materials.PSL_Bottom}
        />
      </group>
    </group>
  )
}

export default Model_PSL;
