import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Model_VP(props) {
  const { nodes, materials } = useGLTF('/VPGLTF.gltf')
  return (
    <group {...props} dispose={null}>
      <group position={[0.2, -0.8, 0]} rotation={[0.5, 0.4, 0.2]} scale={0.0025}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.EXPORT_GOOGLE_SAT_WM010.geometry}
          material={materials.VP_Top}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.EXPORT_GOOGLE_SAT_WM010_1.geometry}
          material={materials.VP_bottom}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.EXPORT_GOOGLE_SAT_WM010_2.geometry}
          material={materials.Build}
        />
      </group>
    </group>
  )
}
export default Model_VP;