import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import MaskImg from "../../Assets/artist-img.svg";
function NonPeakHour({language}) {
  const body_zh1 = "大帽山昂然屹立新界中部高達957米，是香港最高峰；環繞其四周的，便是佔地一千四百四十公頃的大帽山郊野公園。成立於一九七九年大帽山郊野公園，東面及南面分別接鄰大埔滘自然謢理區及城門郊野公園，西面為荃錦公路，及一路之隔的大欖郊野公園；北面為林村。由於大帽山一帶山巒起伏，農業並不發達，昔日區內農民只能種植稻米和蔬菜作為自用，而種茶亦曾是大帽山地區一種興盛的行業，這裏曾經出產一種很有名的綠茶。在大帽山高處的山坡，向下走的人字形梯田仍清晰可見，相傳這是在十七世紀曾經盛極一時的茶場遺址。";
  const body_zh2 = "大帽山被譽為香港最寒冷的地方, 能一暏香港罕見的結霜景觀。為了呼應這種寒冷的氛圍，大帽山的顏色選擇以灰冷的森林色調為主。這些色彩包括淡灰、冷灰和石灰綠，營造出一種冰冷而神秘的感覺。這種色調與大帽山所在的森林環境相契合，同時也突顯了大帽山的特殊氣候條件。";
  const body_en1 = "TaiMoShan";
  const body_en2 = "TaiMoShan";
  return (
    <div>
{language === 'zh' ? (
      <Container className="home-content" >
        <Row className="background-white" >
          <Col className="popup-header-zh">
          非繁忙時間
          </Col>
            <div className="box" style={{marginLeft:12}}>
              <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
            </div>
            <p className="about-artist-body">
            簡介
            </p>
              <p className="about-artist-body">{body_zh1}</p>
            <p className="about-artist-body">
            簡介
            </p>
            <p className="about-artist-body">{body_zh2}</p>
        </Row>
      </Container>
    ) : (
      <Container className="home-content" >
        <Row className="background-white" >
          <Col className="popup-header-zh">
          Non Peak Hour
          </Col>
            <div className="box" style={{marginLeft:12}}>
              <img src={MaskImg} style={{ marginTop:-1 , marginBottom:-1 , marginRight: 1}}/>
            </div>
            <p className="about-artist-body">
            Introduction
            </p>
              <p className="about-artist-body">{body_en1}</p>
            <p className="about-artist-body">
            Introduction2
            </p>
            <p className="about-artist-body">{body_en2}</p>
        </Row>
      </Container>
      )}
      </div>
  );
}

export default NonPeakHour;